<template>
  <div class="app-container">
    <div id="app" class="app">
    <NoiseTop v-if="!showNoiseTop" />
    <Header />
    <main>
      <router-view />
    </main>
    <Footer class="max-width" />
    <CookieConsent v-if="showCookieConsent" />
    <CookieModal />
    <NoiseBottom />
  </div>
  </div>
</template>

<script>
const CookieConsent = () => import("@/components/CookieConsentImpl.vue");
const CookieModal = () => import("@/components/common/CookieModal.vue");
const Header = () => import("@/components/Header");
const NoiseTop = () => import("@/components/NoiseTop");
import lazyLoadComponent from "@/helpers/lazy-load-component.js";
import FooterSkeletonBox from "@/components/common-skeleton-loaders/FooterSkeletonBox";
import NoiseBottomSkeletonBox from "@/components/common-skeleton-loaders/NoiseBottomSkeletonBox";

export default {
  name: "App",
  components: {
    CookieConsent,
    CookieModal,
    Footer: lazyLoadComponent({
      componentFactory: () => import("@/components/Footer.vue"),
      loading: FooterSkeletonBox
    }),
    Header,
    NoiseBottom: lazyLoadComponent({
      componentFactory: () => import("@/components/NoiseBottom.vue"),
      loading: NoiseBottomSkeletonBox
    }),
    NoiseTop
  },
  data() {
    return {
      showCookieConsent: false,
      vh: window.innerHeight * 0.01
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    onHomePage() {
      return this.currentPath == "/";
    },
    onBokforingsPage() {
      return this.path === "/bokforingsangest";
    },
    onPricePage() {
      return this.path === "/priser";
    },
    onProductPage() {
      return this.path === "/fakturerings-bokföringsprogram";
    },

    onEuroFinansPage() {
      return this.currentPath === "/eurofinans";
    },

    onPrioritetFinansPage() {
      return this.currentPath === "/prioritetfinans";
    },
    onAllHomePages() {
      return (
        this.onHomePage ||
        this.onEuroFinansPage ||
        this.onPrioritetFinansPage ||
        this.onPricePage ||
        this.onProductPage ||
        !this.onBokforingsPage
      );
    },
    showNoiseTop() {
      return (
        this.onAllHomePages ||
        this.currentPath === "/kontaktaoss" ||
        this.currentPath === "/cookie-policy"
      );
    }
  },

  methods: {
    handleResize() {
      this.$store.commit("base/setViewWidth", window.innerWidth);
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${this.vh}px`);
    }
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    document.documentElement.style.setProperty("--vh", `${this.vh}px`);

    setTimeout(() => (this.showCookieConsent = true), 500);

    if(process.env.VUE_APP_ENVIRONMENT !== 'production')
    {
        console.log("ENVIRONMENT: " + process.env.VUE_APP_ENVIRONMENT);
    }

  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
